import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";
import { MainTable, OrderStatusLabel, Pagination, TableBody, dateTime, useAuthContext, useDocumentTitle, useFilter } from "suregifts-component-lib";
import { FilterSection } from "./FilterSection";
import { fixedCellStyle } from "../../../helpers/cssHelper";
import { UtilityFilter, useGetUtilities } from "../../../services/utility";
import { TopBottomLabel } from "../../../components/molecules/TopBottomLabel";
import ReplyIcon from "@mui/icons-material/Reply";
import { useQueryClient } from "react-query";
import { useState } from "react";

import RedemptionInfoModal from "./RedemptionInfoModal";
function BillsPaymentScreen() {
  const [filter, setFilter] = useFilter<UtilityFilter>({ page: 1, search: "" });
  const { data, isFetching: isLoading } = useGetUtilities(filter);
  const { user } = useAuthContext();
  const handleFilter = (params: any) => {
    setFilter((prev) => ({ ...prev, ...params }));
  };
  useDocumentTitle("Bills Payments");
  const [transactionId, setTransactionId] = useState<any>();
  const queryClient = useQueryClient();
  const handleClose = () => {
    queryClient.invalidateQueries("bills-payments");
    setTransactionId(undefined);
  };
  return (
    <div>
      <FilterSection filter={filter} handleFilter={handleFilter} total={data?.total} />
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Order No.</TableCell>
            <TableCell>Requester</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Narration</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>
                <TopBottomLabel top={`${item.firstName} ${item.lastName}`} bottom={item.email} />
              </TableCell>

              <TableCell style={fixedCellStyle}>{dateTime(item.dateCreated)}</TableCell>

              <TableCell>{item.narration}</TableCell>
              <TableCell style={fixedCellStyle}>
                <OrderStatusLabel label={item.status} status={item.statusId} />
              </TableCell>
              <TableCell>{item.subTotal}</TableCell>
              <TableCell>
                {item.canRefund && (
                  <IconButton
                    onClick={() => {
                      setTransactionId(item.invoiceNumber);
                    }}
                  >
                    <ReplyIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination total={data?.total} page={filter.page} onChange={(page) => handleFilter({ page })} />
      </div>
      {transactionId && <RedemptionInfoModal handleClose={handleClose} transactionId={transactionId} />}
    </div>
  );
}

export default BillsPaymentScreen;
