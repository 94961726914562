import { TableHead, TableRow, TableCell } from "@mui/material";
import moment from "moment";
import { MainTable, Pagination, TableBody, VoucherOperationType, dateTime } from "suregifts-component-lib";
import { fixedCellStyle } from "../../../helpers/cssHelper";
import { useGetTransactions } from "../../../services/merchant-dashboard/voucher";

export const TableView = ({ filter, handleFilter }) => {
  const { data, isFetching: isLoading } = useGetTransactions({ ...filter, typeId: 8 });
  return (
    <>
      <MainTable loading={isLoading} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Voucher Code</TableCell>
            <TableCell>Amount</TableCell>

            <TableCell sx={{ maxWidth: "30%" }}>Store</TableCell>
            <TableCell>channel</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.code}</TableCell>
              <TableCell sx={{ color: item.typeId === VoucherOperationType.RedemptionReversal ? "info.main" : "success.main" }}>{item.amount}</TableCell>
              <TableCell>{item.narration}</TableCell>
              <TableCell style={fixedCellStyle}>{item.usageMedium}</TableCell>

              <TableCell style={fixedCellStyle}>{dateTime(item.dateTime)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={(page) => handleFilter({ page })} />
      </div>
    </>
  );
};
