import { Dialog, DialogContent, Stack } from "@mui/material";
import { DialogTitle, FormButton, LoadingBox } from "suregifts-component-lib";

import { toast } from "react-toastify";
import { useGetRedemptionById, useReverseRedemption } from "../../../services/vouchers";
import { ReverseTransactionInfo } from "../../account/redemptions/ReverseTransactionInfo";

type RedemptionInfoModalProps = {
  transactionId: any;
  handleClose: () => void;
};
function RedemptionInfoModal({ transactionId, handleClose }: RedemptionInfoModalProps) {
  const { data, isLoading } = useGetRedemptionById(transactionId);
  const { mutate: reverse, isLoading: sending } = useReverseRedemption();

  if (!data || isLoading) {
    return (
      <Dialog open maxWidth="xs">
        <LoadingBox loading />
      </Dialog>
    );
  }

  return (
    <Dialog open maxWidth="xs" scroll="paper">
      <DialogTitle title="Redemption Details" handleClose={handleClose} />
      <DialogContent>
        <ReverseTransactionInfo data={data} />
        <Stack spacing={2} direction={"row"} sx={{ marginTop: "30px" }}>
          <FormButton
            loading={sending}
            color={"error"}
            fullWidth
            label={"Cance Order"}
            onClick={() => {
              reverse(
                { transactionId, amountUsed: 0 },
                {
                  onSuccess: () => {
                    toast.success("Order canceled successfully.");
                    handleClose();
                  },
                }
              );
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default RedemptionInfoModal;
