import { TableHead, TableRow, TableCell } from "@mui/material";

import { MainTable, Pagination, TableBody, VoucherOperationType, dateTime } from "suregifts-component-lib";

import { fixedCellStyle } from "../../../helpers/cssHelper";
import { useGetTransactions } from "../../../services/vouchers";

export const TableView = ({ filter, handlePage }) => {
  const { data, isFetching } = useGetTransactions({ ...filter, typeId: 1 });

  return (
    <>
      <MainTable loading={isFetching} sx={{ marginTop: "40px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Voucher code</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell style={{ width: "25%" }}>Store</TableCell>
            <TableCell style={{ width: "25%" }}>Vendor</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.items.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={fixedCellStyle}>{item.code}</TableCell>
              <TableCell>{item.amount}</TableCell>

              <TableCell>{item.pointOfUsage}</TableCell>
              <TableCell>{item.merchantName}</TableCell>
              <TableCell style={fixedCellStyle}>{dateTime(item.dateTime)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MainTable>
      <div style={{ marginTop: "1.25rem" }}>
        <Pagination page={filter.page} total={data?.total} onChange={handlePage} />
      </div>
    </>
  );
};
