import Box from "@mui/material/Box";
import { useMemo, useState } from "react";
import { LightModeIcon, LogoutIcon, NavContainer, NavLink, SettingsIcon, useAuthContext, useThemeContext } from "suregifts-component-lib";
import accountLinks from "./links/accountLinks";
import customerSupportLinks from "./links/customerSupportLinks";
import { merchantBackendLinks } from "./links/merchantBackendLinks";

import PreferenceModal from "../../preference/PreferenceModal";
import { RolesEnum, useLogout } from "../../../services/auth/auth.api";
import { Divider } from "@mui/material";
import { useGetMerchantDashboardLinks } from "./links/merchantDashboardLinks";
import { useGetSuperAdminLinks } from "./links/superAdminLinks";

function NavBar() {
  const merchantDasboardLinks = useGetMerchantDashboardLinks();
  const superAdminLinks = useGetSuperAdminLinks();
  const [showPreference, setShowPreference] = useState(false);
  const { user, signOut } = useAuthContext();
  const { mutate: logout } = useLogout();

  const links = useMemo(() => {
    if (user?.role == RolesEnum.SUPER_ADMIN || user?.role === RolesEnum.ADMIN) {
      return superAdminLinks;
    } else if (user?.role === RolesEnum.FINANCE) {
      return accountLinks;
    } else if (user?.role === RolesEnum.CUSTOMER_SUPPORT || user?.role === RolesEnum.SENIOR_SUPPORT) {
      return customerSupportLinks;
    } else if (user?.role === RolesEnum.BUSINESS_DEVELOPMENT) {
      return merchantBackendLinks;
    } else if (user?.role === RolesEnum.STORE_CONTROLLER || user?.role === RolesEnum.STORE_PERSONNEL) {
      return merchantDasboardLinks;
    }
    return [];
  }, [user, merchantDasboardLinks]);
  return (
    <>
      <Box
        sx={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        component="ul"
      >
        {links.map((link) => (
          <li key={link.title}>
            <NavLink {...link} />
          </li>
        ))}
        <li>
          <div style={{ padding: "10px 30px" }}>
            <Divider flexItem />
          </div>
        </li>
        <li>
          <NavLink
            title="Settings"
            to="to"
            onClick={(e) => {
              e.preventDefault();
              setShowPreference(true);
            }}
            icon={SettingsIcon}
          />
        </li>
        <li
          style={{
            flexGrow: 1,
            minHeight: "100px",
          }}
        ></li>
        {[
          {
            to: "/logout",
            icon: LogoutIcon,
            title: "Logout",
            onClick: (e) => {
              e.preventDefault();
              logout(undefined, {
                onSuccess: () => {
                  signOut();
                },
              });
            },
          },
        ].map((link) => (
          <li key={link.title}>
            <NavLink {...link} />
          </li>
        ))}
      </Box>
      {showPreference && <PreferenceModal open={showPreference} handleClose={() => setShowPreference(false)} />}
    </>
  );
}

export default NavBar;
