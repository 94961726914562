import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useForm } from "react-hook-form";
import { DialogTitle, FormButton } from "suregifts-component-lib";
import { ApplicationData, CreateEditMerchantModel, useAcceptApplication } from "../../../../services/merchants";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { merchantSchema } from "../../merchants/modals/merchantSchema";
import { MerchantAddEditForm } from "../../merchants/modals/MerchantAddEditForm";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useState } from "react";

function AcceptApplicationModal() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("..");
  };
  const [application] = useState(() => {
    const data = sessionStorage.getItem("APPLICATION_DATA");
    if (!data) return {} as ApplicationData;
    return JSON.parse(data) as ApplicationData;
  });

  const { mutate: create, isLoading } = useAcceptApplication(application.id);
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateEditMerchantModel>({
    defaultValues: {
      categories: [],
      paymentType: "",
      phone: application?.phone,
      email: application?.email,
      name: application?.name,
    },
    resolver: yupResolver(merchantSchema),
  });
  const submitForm = (data: CreateEditMerchantModel) => {
    data.redmeptionDetails = [data.redmeptionDetail];
    create(data, {
      onSuccess: () => {
        toast.success("Application Accepted successfully.");
        queryClient.invalidateQueries("applications");
        handleClose();
      },
    });
  };
  return (
    <div>
      <Dialog maxWidth={"md"} open scroll="paper">
        <DialogTitle title="Add Merchant" handleClose={handleClose} />
        <DialogContent>
          <MerchantAddEditForm control={control} errors={errors} register={register} />
        </DialogContent>
        <DialogActions>
          <FormButton loading={isLoading} onClick={handleSubmit(submitForm)} label="Submit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AcceptApplicationModal;
