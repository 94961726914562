import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatMoney } from "suregifts-component-lib";
import CreditDebitModal from "./CreditDebitModal";

import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { WalletByIdData } from "./data";
type BalanceCardProps = {
  store: WalletByIdData;
  credit?: any;
  debit?: any;
};
export const BalanceCard = ({ store, debit, credit }: BalanceCardProps) => {
  const [showModal, setShowModal] = useState(0);
  const queryClient = useQueryClient();
  const handleClose = () => {
    queryClient.invalidateQueries("store-wallet-history");
    queryClient.invalidateQueries("store-wallet-by-id");

    queryClient.invalidateQueries("merchant-wallet-history");
    queryClient.invalidateQueries("merchant-wallet-by-id");
    setShowModal(0);
  };
  return (
    <div style={{ alignSelf: "center" }}>
      <Typography sx={{ fontSize: "0.625rem", color: "#AAAABE", textTransform: "uppercase" }}>Balance</Typography>
      <Typography sx={{ fontSize: "2.75rem", fontWeight: 700 }}>{formatMoney(store.balance)}</Typography>
      <Typography sx={{ fontSize: "0.625rem", color: "#AAAABE", textTransform: "uppercase" }}>top up wallet via:</Typography>
      <Box
        sx={(theme) => ({
          marginTop: "0.9375rem",
          display: "grid",
          gap: "12px",
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        })}
      >
        {credit && <FundButton label="Credit Wallet" onClick={() => setShowModal(1)} />}
        {debit && <FundButton label="Debit Wallet" onClick={() => setShowModal(2)} />}
      </Box>
      {showModal > 0 && (
        <CreditDebitModal
          title={showModal === 1 ? "Credit Wallet" : "Debit Wallet"}
          handleClose={handleClose}
          onSubmit={(model) => {
            if (showModal === 1) {
              credit(model, {
                onSuccess: () => {
                  toast.success("Credit was successful.");
                  handleClose();
                },
              });
            } else {
              debit(model, {
                onSuccess: () => {
                  toast.success("Debit was successful.");
                  handleClose();
                },
              });
            }
          }}
        />
      )}
    </div>
  );
};
type FundButtonProps = {
  label: string;
  onClick?: () => void;
};

const FundButton = ({ label, onClick }: FundButtonProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        cursor: "pointer",
        borderRadius: "0.9375rem",
        padding: "1.125rem 0.9375rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        overflow: "hidden !important",
        "&:hover": {
          backgroundColor: "primary.light",
        },
      }}
      onClick={onClick}
    >
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "1.25rem", width: "2.5rem", height: "2.5rem", backgroundColor: "primary.dark", overflow: "hidden" }}>
        <Box component={"img"} src="/assets/images/bank-transfer.png" />
      </Box>
      <span style={{ fontWeight: 600, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{label}</span>
    </Box>
  );
};
