import { Box } from "@mui/material";
import { useGetCountries } from "../../../../services/utility";
import { SearchContainer, PageHeaderTitle, FilterDropdown } from "suregifts-component-lib";

type FilterSectionProps = {
  filterChanged?: (countryCode: string) => void;
};

function FilterSection({ filterChanged = () => {} }: FilterSectionProps) {
  const { data: countries } = useGetCountries((data) => {
    if (data.length) {
      const countryCode = data[0].id as string;
      filterChanged(countryCode);
    }
  });
  return (
    <SearchContainer sx={{ marginTop: "40px" }}>
      <PageHeaderTitle title={"Bill Categories"} />
      <Box
        sx={{
          display: "flex",
          gap: "1.25rem",
          alignItems: "center",
        }}
      >
        <FilterDropdown
          display={"Country"}
          options={countries?.map((x) => ({ text: x.name }))}
          onChange={(index) => {
            filterChanged(countries![index].id);
          }}
        />
      </Box>
    </SearchContainer>
  );
}

export default FilterSection;
