import { useState } from "react";
import FilterSection from "./FilterSection";
import ListView from "./ListView";
import { MarginTop40Box, useDocumentTitle } from "suregifts-component-lib";

function BillCategoriesScreen() {
  const [countryCode, setCountryCode] = useState("");
  useDocumentTitle("Bill Categories");
  return (
    <div>
      <FilterSection filterChanged={(countryCode) => setCountryCode(countryCode)} />
      <ListView countryCode={countryCode} />
    </div>
  );
}

export default BillCategoriesScreen;
